<template>
  <div class="userManage bgblue">
    <div class="orderManage-top">
      <el-row :gutter="10">
        <el-col :span="4">
          <div class="order-pic shadow">
            <div class="pic-top">
              <img
                src="../../assets/images/index_1.png"
                width="40"
                class="vm"
              />
              <span class="vm">新客户</span>
            </div>
            <div class="fw600 mb10">
              {{ queryAgentInfoObj[1][0].New_count }}
            </div>
            <div>
              较昨日<i class="color"
                >&nbsp;&nbsp;{{
                  queryAgentInfoObj[1][0].New_count -
                  queryAgentInfoObj[1][0].NewYesterday_count
                }}</i
              >
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="order-pic shadow">
            <div class="pic-top">
              <img
                src="../../assets/images/index_2.png"
                width="40"
                class="vm"
              />
              <span class="vm">审核驳回</span>
            </div>
            <div class="fw600 mb10">
              {{ queryAgentInfoObj[2][0].Audit_status_overrule_today_count }}
            </div>
            <div>
              较昨日<i class="color"
                >&nbsp;&nbsp;{{
                  queryAgentInfoObj[2][0].Audit_status_overrule_today_count -
                  queryAgentInfoObj[2][0].Audit_status_overrule_yesterday_count
                }}</i
              >
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="order-pic shadow">
            <div class="pic-top">
              <img
                src="../../assets/images/index_5.png"
                width="40"
                class="vm"
              />
              <span class="vm">审核通过</span>
            </div>
            <div class="fw600 mb10">
              {{ queryAgentInfoObj[2][0].Audit_status_Pass_today_count }}
            </div>
            <div>
              较昨日<i class="color"
                >&nbsp;&nbsp;{{
                  queryAgentInfoObj[2][0].Audit_status_Pass_today_count -
                  queryAgentInfoObj[2][0].Audit_status_Pass_yesterday_count
                }}</i
              >
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="order-pic shadow">
            <div class="pic-top">
              <img
                src="../../assets/images/index_4.png"
                width="40"
                class="vm"
              />
              <span class="vm">报装完成</span>
            </div>
            <div class="fw600 mb10">{{ queryAgentInfoObj[3][0].BZ_count }}</div>
            <div>
              较昨日<i class="color"
                >&nbsp;&nbsp;{{
                  queryAgentInfoObj[3][0].BZ_count -
                  queryAgentInfoObj[3][0].BZYesterday_count
                }}</i
              >
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="order-pic shadow">
            <div class="pic-top">
              <img
                src="../../assets/images/index_3.png"
                width="40"
                class="vm"
              />
              <span class="vm">井网预警</span>
            </div>
            <div class="fw600 mb10">{{ queryAgentInfoObj[4][0].Bw_count }}</div>
            <div>
              较昨日<i class="color"
                >&nbsp;&nbsp;{{
                  queryAgentInfoObj[4][0].Bw_count -
                  queryAgentInfoObj[4][0].BwYesterday_count
                }}</i
              >
            </div>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="order-pic shadow">
            <div class="pic-top">
              <img
                src="../../assets/images/index_3.png"
                width="40"
                class="vm"
              />
              <span class="vm">客户总数</span>
            </div>
            <div class="fw600 mb10">
              {{ queryAgentInfoObj[0][0].number_count }}
            </div>
            <div>
              较昨日<i class="color"
                >&nbsp;&nbsp;
                {{
                  queryAgentInfoObj[0][0].number_count -
                  queryAgentInfoObj[0][0].numberYesterday_count
                }}</i
              >
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="userContent">
      <el-row :gutter="10">
        <el-col :span="18" style="background-color: #fff">
          <div class="tab">
            <div class="tab-right">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="客户数据" name="first"></el-tab-pane>
                <el-tab-pane label="审核通过" name="7"></el-tab-pane>
                <el-tab-pane label="安装完成" name="1"></el-tab-pane>
                <el-tab-pane label="并网完成" name="2"></el-tab-pane>
              </el-tabs>
            </div>
            <div class="tab-left textr">
              <el-radio-group v-model="radio1" size="mini">
                <el-radio-button label="本月"></el-radio-button>
                <el-radio-button label="今年"></el-radio-button>
                <el-radio-button label="自定义"></el-radio-button>
              </el-radio-group>
              <el-date-picker
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="margin-left: 20px"
              >
              </el-date-picker>
            </div>
            <div class="tab-bottom">
              <el-table
                v-loading="tableLoading"
                :data="tableData"
                border
                height="calc(100vh - 360px)"
              >
                <el-table-column prop="agentName" label="归属公司">
                </el-table-column>
                <el-table-column
                  min-width="118px"
                  label="订单号"
                  prop="customerNumber"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  width="70"
                  prop="customerName"
                  label="客户名称"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="address"
                  min-width="150px"
                  label="安装地址"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="projectCompanyName"
                  min-width="150px"
                  label="项目公司"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  align="center"
                  min-width="100px"
                  width="100px"
                  prop="capacity"
                  class-name="row-padding"
                  label="装机容量(KW)"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  align="center"
                  max-width="100px"
                  width="100px"
                  class-name="row-padding"
                  prop="moudleNum"
                  label="组件数量(片)"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  align="center"
                  max-width="100px"
                  width="100px"
                  class-name="row-padding"
                  prop="moudleType"
                  label="组件类型"
                >
                </el-table-column>
                <!-- 订单进度 -->
                <el-table-column
                  show-overflow-tooltip
                  align="center"
                  max-width="100px"
                  width="100px"
                  class-name="row-padding"
                  prop="orderSchedule"
                  label="订单进度"
                >
                  <template slot-scope="{ row }">
                    <el-button
                      v-show="row.orderSchedule"
                      :class="getColor(row.orderSchedule)"
                      >{{ row.orderSchedule }}</el-button
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  align="center"
                  max-width="100px"
                  width="100px"
                  class-name="row-padding"
                  prop="sellName"
                  label="销售姓名"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  align="center"
                  max-width="100px"
                  width="100px"
                  class-name="row-padding"
                  prop="orderStatus"
                  label="订单状态"
                >
                </el-table-column>
                <el-table-column
                  align="center"
                  max-width="80px"
                  width="80px"
                  prop="isBz"
                  class-name="overflowTip"
                  label=" 报装"
                >
                  <template slot-scope="{ row }">
                    <el-tag type="success" v-if="row.isBz == 1">已报装</el-tag>
                    <el-tag type="danger" v-else>未报装</el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  max-width="80px"
                  width="80px"
                  class-name="overflowTip"
                  prop="isBw"
                  label="并网"
                >
                  <template slot-scope="{ row }">
                    <el-tag type="success" v-if="row.isBw == 1">已并网</el-tag>
                    <el-tag type="danger" v-else>未并网</el-tag>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="150px"
                  show-overflow-tooltip
                  align="center"
                  prop="createDate"
                  label="录入时间"
                >
                </el-table-column>
                <el-table-column
                  max-width="60px"
                  width="60px"
                  show-overflow-tooltip
                  align="center"
                  prop="createName"
                  label="录入人"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  align="center"
                  prop="remark"
                  label="备注"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  align="center"
                  prop="updateName"
                  label="更新人"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  align="center"
                  prop="updateDate"
                  label="更新时间"
                >
                </el-table-column>
              </el-table>
              <div class="pagination" style="text-align: end">
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="queryInfo.currPage"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="queryInfo.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="content-top">
            <div class="test">
              <div class="test-top">待办事项</div>
              <el-row>
                <el-col :span="8">
                  <div class="newTest">
                    <p>待勘探</p>
                    <div>0</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="newTest">
                    <p>待发货</p>
                    <div>0</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="newTest">
                    <p>待安装</p>
                    <div>0</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="content-top test" style="margin-top: 10px">
            <div class="test-top flex justify-between">
              <div class="">通知(0)</div>
              <div><el-button type="text">查看全部></el-button></div>
            </div>
            <div class="margin10" style="height: calc(100vh - 522px)">
              <div class="textc mt100">
                <img src="../../assets/images/null.png" alt="" width="150" />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableLoading: false,
      activeName: "first",
      value1: "",
      radio1: "本月",
      currentPage4: 1,
      queryInfo: {
        pageSize: 10,
        currPage: 1,
        condition: {
          agentCode: "",
          customerName: "",
          orderSchedule: "",
          customerNumber: "",
        },
      },
      tableData: [],
      queryAgentInfoObj: [], // 页面头部数据
      agentCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
        .agentCode,
      total: "",
    };
  },
  created() {
    this.getQueryAgentIndexInfo();
    this.getTableData();
  },
  methods: {
    // 获取页面头部数据
    async getQueryAgentIndexInfo() {
      let res = await this.$http.post("/houseCustomer/queryAgentlist", {
        agentCode: this.agentCode,
      });
      if (res.data.code == 200) {
        this.queryAgentInfoObj = res.data.data || [];
      }
    },
    // 获取表格数据
    async getTableData() {
      this.tableLoading = true;
      let data = JSON.parse(JSON.stringify(this.queryInfo));
      data.condition.agentCode = this.agentCode;
      console.log(
        JSON.parse(localStorage.getItem("userInfo")).userInfo.agentCode,
        445
      );
      // data.condition.userCodeList = JSON.parse(
      //   localStorage.getItem("userInfo")
      // ).userInfo.userCodeList;
      let res = await this.$http.post("houseCustomer/lists", data);
      this.tableLoading = false;
      if (res.data.code == 200) {
        console.log(res.data.data, 960);
        this.total = res.data.data.count;
        this.tableData = res.data.data.data;
      }
    },
    // 更改表格订单进度颜色
    getColor(str) {
      var className = "btn1";
      // this.setColorByStatus.forEach(item => {
      //   console.log(item.status)
      //   if (item.status == str) {
      //     className = className + ' ' + item.className
      //   }
      // })
      return className;
    },
    // async tabData(data) {
    //   let res = await this.$http.post("houseCustomer/lists", data);
    //   this.tableLoading = false;
    //   if (res.data.code == 200) {
    //     console.log(res.data.data, 960);
    //     this.total = res.data.data.count;
    //     this.tableData = res.data.data.data;
    //   }
    // },
    handleClick(tab, event) {
      if (tab.name == 7) {
        this.queryInfo.type = 2;
        this.getTableData();
      } else if (tab.name == 1) {
        this.queryInfo.type = 3;
        this.getTableData();
      } else if (tab.name == 2) {
        this.queryInfo.type = 4;
        this.getTableData();
      } else {
        this.queryInfo.type = "";
        this.getTableData();
      }
      console.log(tab.name, event, 977);
    },

    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getTableData();
    },
  },
};
</script>

<style lang="less">
.userManage {
  tr.el-table__row:hover > td {
    background-color: #f59a23 !important;
    cursor: pointer;
  }

  margin-right: 5px;
  padding-bottom: 1px;

  .content-bottom {
    background-color: #fff;
  }

  .content-top {
    background-color: #fff;
    margin-left: 5px;
    padding: 5px;
  }

  .el-tabs__item {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .el-tabs__nav {
    border-bottom: 1px solid #ccc;
  }

  .el-tabs__content {
    margin-top: 10px;
  }

  .el-radio-button--mini .el-radio-button__inner {
    padding: 5px 10px;
  }

  .el-date-editor .el-input__icon {
    line-height: 21px !important;
  }

  .orderManage-top {
    padding: 10px;

    .order-pic {
      background-color: #fff;
      text-align: center;
      box-sizing: border-box;
      padding: 5px 5px 10px 5px;
      box-sizing: border-box;
      font-size: 14px;

      .color {
        color: red;
        font-style: normal;
      }

      .pic-top {
        margin: 5px auto;
      }

      .el-icon-user {
        border-radius: 50%;
        width: 35px;
        height: 35px;
        line-height: 30px;
        background-color: rgb(239, 246, 254);
        text-align: center;
        margin-right: 5px;
        color: #4c92f2;
        font-size: 18px;
      }
    }
  }

  .userContent {
    margin: 0 10px 5px 15px;

    .test {
      font-size: 14px;
      font-weight: 800;
      padding-bottom: 10px;

      .test-top {
        padding: 10px;
        border-bottom: 1px solid #e7e7e7;
      }
    }

    .newTest {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .information {
      position: relative;
    }

    .tab {
      padding: 8px;
      box-sizing: border-box;
      font-size: 12px;
      background-color: #fff;
    }
  }

  .vm {
    vertical-align: middle;
    display: inline-block;
  }
}
</style>
